html,
body {
    background-color: #f8f7fa;
    padding-bottom: 20px;
}

html.login-page,
body.login-page,
html.checkout-page,
body.checkout-page {
    padding-bottom: 0px !important;
    overflow-x: hidden;
}


.navy {
    background-color: #303348 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0px !important;

}

.nav-align {
    align-items: center;
}

.nav-link {
    color: #e1def5e6 !important;
}

.body3 {
    font-size: 12px;
}

.sidebar .navbar-nav {
    margin-top: 220px;
    /* Add some top margin if needed */
}

.dropMenu {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 20px;
}

.userStatsPie {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

}

.checkout-page .header-container .background-wrapper {

    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    width: 100% !important;
    height: 150px !important;
    position: relative !important;
}


.checkout-container {
    max-width: 1200px;
}

.form-control {
    border-radius: 8px;
    padding: 12px;
}

.checkout .form-control:focus {
    box-shadow: none;
    border-color: #b41d1d;
}

.checkout-page .form-control:focus {
    box-shadow: none;
    border-color: #b41d1d;
}

.checkout-page .rmsc {
    box-shadow: none;
    border-color: #b41d1d;
    --rmsc-main: #b41d1d;
}

.checkout-page .rmsc .dropdown-content {
    position: relative;
}

.checkout-page input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
}

.checkout-page input[type="checkbox"] {
    accent-color: #ff5d25;
}

.card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.shadow-sm {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Credit Card Preview Styles */

.credit-card-preview {
    background: linear-gradient(135deg, #0b9eb7, #4a4a4a);
    color: white;
    height: 300px;
    perspective: 1000px;
    border: none !important;
    box-shadow: 0px 15px #F79E1B, 0px 30px silver;
}

.card-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chip {
    width: 60px;
    height: 45px;
    background: linear-gradient(45deg, #c0a883, #e5d8b8);
    border-radius: 5px;
}

.credit-logo {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    color: #ff5f00;
}

.card-number {
    font-family: 'Courier New', monospace;
    font-size: 1.4rem;
    letter-spacing: 2px;
    text-align: center;
    margin: 15px 0;
}

.card-details {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
}

.btn-payment {
    background-color: #ff5d25;
    border-color: #e97649;
}

.btn-payment:hover {
    background-color: #c45a30;
    border-color: #e97649;
}

.btn-payment:focus-visible,
.btn-payment:active {
    background-color: #ff5d25 !important;
    border-color: #e97649 !important;
    box-shadow: none !important;
}

.pack ul {
    padding-left: 0;
}

/* Responsive Adjustments */

@media only screen and (max-width: 991px) {
    .navy {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .nav-align {
        align-items: start;
        padding-left: 25px;
    }

    .dropMenu {

        margin-left: 0px;
        margin-top: 10px;
    }
}

@media (max-width: 576px) {
    .credit-card-preview {
        height: 210px;
        padding: 15px;
    }

    .card-front {
        padding: 0;
    }

    .card-details {
        padding-right: 30px;
        padding-bottom: 20px;
    }

    .card-number {
        font-size: 1rem;
    }

    .card-details {
        font-size: 0.8rem;
    }

    .credit-logo {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .bg-md-login {
        background-image: url(/public/mob-login.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
    }

    .checkout-container {
        padding: 15px;
    }

    .card {
        margin-bottom: 20px;
    }



}